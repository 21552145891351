
import _ from 'lodash'
import VerificationCode from '@/components/business/verification_code.vue';
import SubmitSuccess from '@/components/business/submit-success.vue';
export default {
  components: {
    VerificationCode,
    SubmitSuccess
  },
  data () {
    return {
      showScroll:true,
      showBanner: true,
      bannerDialog: true,
      visible: false,
      // 联系电话
      telephone: '400-660-8948',
      // 各种动画属性
      scrollTop: 0,
      headerHeight: 0,
      footerTop: 0,
      userFormHeight: 0,
      currentPath: '',
      // 线索表单
      info: {
        formFlag:false,
        popularizeStoreName: '',
        popularizePhone: '',
        popularizeName: '',
        popularizeVerificationCode: '',
        fromPage:''
      },
      flag:true,
      //   
      //   
      //   { title: '组织研学中心', path: '/researchCenter', activeIndex: 'researchCenter' },
      // 顶部菜单栏
      navRouter: [
        { title: '首页', path: '/home', activeIndex: 'home' },
        { 
          title: '电商教育', 
          path: '', 
          activeIndex: 'eTrain',
          popperClass: 'edu',
          children: [
            { title: '淘宝天猫策略班', path: '/combatClass', activeIndex: 'combatClass', desc: '2天1夜' },
            { title: '抖音电商策略班', path: '/combat_dy', activeIndex: 'combat_dy', desc: '2天1夜' },
            // { title: '全域电商系统策略班', path: '/combat_3', activeIndex: 'combat3', desc: '3天2夜' },
            { title: '淘系特训营', path: '/eTaoBao', activeIndex: 'eTaoBao', desc: '6天6夜' },
            { title: '抖音特训营', path: '/tiktok', activeIndex: 'tikTok', desc: '5天4夜' },
            { title: '电商财税特训营', path: '/financeClass', activeIndex: 'financeClass', desc: '3天2夜' },
            { title: '京东特训营', path: '/jdCombat', activeIndex: 'jdCombat', desc: '3天2夜' },
            { title: '拼多多特训营', path: '/pddCombat', activeIndex: 'pddCombat', desc: '3天2夜' },
            { title: '小红书特训营', path: '/redBook', activeIndex: 'redBook', desc: '3天2夜' },
            { title: '亚马逊出海特训营', path: '/ymxCombat', activeIndex: 'ymxCombat', desc: '2天1夜' },
            // { title: 'TikTok特训营', path: '/hwtiktok', activeIndex: 'hwtikTok', desc: '3天2夜' },
            { title: '1688成长特训营', path: '/ali1688Class', activeIndex: 'ali1688Class', desc: '3天2夜' },
            { title: '经营决策班 ', path: '/brandClass', activeIndex: 'brandClass', desc: '3天2夜' },
            { title: '新锐品牌策划班', path: '/decisionClass', activeIndex: 'decisionClass', desc: '3天2夜' },
            { title: '骨干复制班 ', path: '/performanceClass', activeIndex: 'performanceClass', desc: '3天2夜' },
            { title: '新品开发策划班', path: '/productClass', activeIndex: 'productClass', desc: '3天2夜' },  
            { title: '增长计划制定班', path: '/growthClass', activeIndex: 'growthClass', desc: '3天2夜' },  
            { title: '布局规划班', path: '/bjClass', activeIndex: 'bjClass', desc: '3天2夜' },  
            { title: '人才识别训练班', path: '/discClass', activeIndex: 'discClass', desc: '3天2夜' },  
            { title: '金牌客服训练营', path: '/kfClass', activeIndex: 'kfClass', desc: '2天1夜' },  
            { title: '私域增长班', path: '/privateDomainClass', activeIndex: 'privateDomainClass', desc: '3天2夜' },  
            { title: '玺承云学堂', path: '', activeIndex: '', url: 'https://yxt.xcect.com/page/1443478?navIndex=0', link: true, desc: '电商在线教育品牌' },
            // { title: '全域电商盈利实战特训营', path: '/eGlobal', activeIndex: 'eGlobal' },
          ]
        }, 
        { 
          title: '电商咨询', 
          path: '', 
          activeIndex: '电商咨询',
          popperClass: 'consult',
          children: [
            { title: '玺承电商俱乐部', path: '/eConsultation', activeIndex: 'eConsultation', desc: '电商咨询创导者' },
            { title: '亿元俱乐部', path: '/billionClub', activeIndex: 'billionClub', desc: '结交100位亿级电商老板' },
            { title: '电商组织研学中心', path: '/researchCenter', activeIndex: 'researchCenter', desc: '组织力 | 驱动业务不断突破' },
            { title: '抖音电商俱乐部', path: '/tiktokClub', activeIndex: 'tiktokClub', desc: '抖音平台销售运营标准体系' },
            { title: '电商财税陪跑营', path: '/csClub', activeIndex: 'csClub', desc: '搭建完整的标准化财税体系' },
            { title: '财税专项咨询', path: '/csSepcClub', activeIndex: 'csSepcClub', desc: '重建电商管理标准，让财税成为生产力' },
            { title: '京东陪跑营', path: '/jdClub', activeIndex: 'jdClub', desc: '多维度解析京东平台' },
            { title: '亚马逊陪跑营', path: '/ymxClub', activeIndex: 'ymxClub', desc: '布局产品体系打造专属产品矩阵' },
            { title: '拼多多陪跑营', path: '/pddClub', activeIndex: 'pddClub', desc: '解决在平台的渠道布局和经营难点' },
            { title: '小红书陪跑营', path: '/redBookClub', activeIndex: 'redBookClub', desc: '内容裂变实现全平台全域内容布局' },
            { title: '1688陪跑营', path: '/aliClub', activeIndex: 'aliClub', desc: '帮您在1688实现利润突破与渠道破局' },
            { title: '全岗位技能提升', path: '/allClub', activeIndex: 'allClub', desc: '为电商企业量身打造的岗位职能培训课程' },
            // { title: 'DGM电商数据增长模型研究中心', path: '/dgmCenter', activeIndex: 'dgmCenter' },
          ] 
        },
        { 
          title: '玺承产服', 
          path: '', 
          activeIndex: '玺承产服',
          popperClass: 'estate',
          children: [
            { title: '玺承产服', path: '/industry', activeIndex: 'industry', desc: '为电商产业生态提供全方位产业配套资源支持的服务' },
            { title: '电商圈子', path: '/eCircle', activeIndex: 'eCircle', desc: '电商界的「黄金圈子」'},
            { title: '玺承游学', path: '/studyTour', activeIndex: 'studyTour', desc: '玺承集团旗下电商游学社交品牌' },
            { title: '玺友会', path: '/xyh', activeIndex: 'xyh', desc: '致力于构建高端电商人脉资源圈' },
            { title: '电商经营委员会', path: '/jywyh', activeIndex: 'jywyh', desc: '企业经营实现确定性增长，持续性保驾护航' },
          ] 
        },
        { title: '玺承数智', path: '/digitalIntel', activeIndex: 'digitalIntel' },
        { title: '玺承资本', path: '/capitalism', activeIndex: 'capitalism' },
        { title: '玺承范儿', path: '/xcFan', activeIndex: 'xcFan' },
        { title: '客户案例', path: '/studentColumn', activeIndex: 'studentColumn' },
        {
           title: '关于玺承', 
           path: '/aboutUs', 
           activeIndex: 'aboutUs',
          //  children: [
          //   { title: '集团简介', path: '?anchor=company', activeIndex: 'company' },
          //   { title: '企业文化', path: '/aboutUs?anchor=culture', activeIndex: 'culture' },
          //   { title: '发展历程', path: '/aboutUs?anchor=develop', activeIndex: 'develop' },
          //   { title: '实战师资', path: '/aboutUs?anchor=teacher', activeIndex: 'teacher' },
          //   { title: '开课城市', path: '/aboutUs?anchor=city', activeIndex: 'city' },
          //   { title: '联系我们', path: '/aboutUs?anchor=touchUs', activeIndex: 'touchUs' },
          //   { title: '玺承范儿', path: '/xcFan'},
          //   
          //   { title: '电商问答', path: '/questionAnswer'},
          //   { title: '电商服务', path: '/eServices'},
          // ] 
         }
      ],
      expand: true,
      isSticky: false,
      // 底部快捷入口
      routerConfig: {
        // 电商培训
        train: [
            [
              { title: '淘宝天猫盈利系统策略班', path: '/combatClass', activeIndex: 'combatClass', desc: '电商老板必修课' },
              { title: '抖音电商盈利系统策略班', path: '/combat_dy', activeIndex: 'combat_dy', desc: '抖音3.0全域布局新玩法' },
              // { title: '全域电商系统策略班', path: '/combat_3', activeIndex: 'combat3', desc: '3天2夜' },
              { title: '淘系特训营', path: '/eTaoBao', activeIndex: 'eTaoBao', desc: '6天6夜' },
              { title: '抖音特训营', path: '/tiktok', activeIndex: 'tikTok', desc: '5天4夜' },
              { title: '电商财税特训营', path: '/financeClass', activeIndex: 'financeClass', desc: '3天2夜' },
              { title: '京东特训营', path: '/jdCombat', activeIndex: 'jdCombat', desc: '3天2夜' },
              { title: '拼多多特训营', path: '/pddCombat', activeIndex: 'pddCombat', desc: '3天2夜' },
              { title: '小红书特训营', path: '/redBook', activeIndex: 'redBook', desc: '3天2夜' },
              { title: '亚马逊出海特训营', path: '/ymxCombat', activeIndex: 'ymxCombat', desc: '2天1夜' },
              // { title: 'TikTok特训营', path: '/hwtiktok', activeIndex: 'hwtikTok', desc: '3天2夜' },
              { title: '1688成长特训营', path: '/ali1688Class', activeIndex: 'ali1688Class', desc: '3天2夜' },
              { title: '经营决策班 ', path: '/brandClass', activeIndex: 'brandClass', desc: '3天2夜' },
              { title: '新锐品牌策划班', path: '/decisionClass', activeIndex: 'decisionClass', desc: '3天2夜' },
              { title: '骨干复制班 ', path: '/performanceClass', activeIndex: 'performanceClass', desc: '3天2夜' },
              { title: '新品开发策划班', path: '/productClass', activeIndex: 'productClass', desc: '3天2夜' },  
              { title: '增长计划制定班', path: '/growthClass', activeIndex: 'growthClass', desc: '2天1夜' }, 
              { title: '布局规划班', path: '/bjClass', activeIndex: 'bjClass', desc: '2天1夜' },  
              { title: '人才识别训练班', path: '/discClass', activeIndex: 'discClass', desc: '2天1夜' },  
              { title: '金牌客服训练营', path: '/kfClass', activeIndex: 'kfClass', desc: '2天1夜' },  
              { title: '私域增长班', path: '/privateDomainClass', activeIndex: 'privateDomainClass', desc: '3天2夜' },  
              { title: '玺承云学堂', path: '', activeIndex: '', url: 'https://yxt.xcect.com/page/1443478?navIndex=0', link: true, desc: '电商在线教育品牌' },
            ], [
             
            ]
        ],
        // 玺承产服
        convenient: [
          { title: '大圣电商圈子', path: '/eCircle' },
          { title: '电商经营委员会', path: '/jywyh' },
          { title: '玺承游学', path: '/studyTour' },
          { title: '玺友会', path: '/xyh' },
          { title: '柚答', path: '/industry' },
          { title: '品牌全案视觉', path: '/industry' },
          { title: '明星经纪服务', path: '/industry' },
          { title: '商业媒体/产业资讯', path: '/industry' },
          { title: '电商人才孵化输送', path: '/industry' },
          { title: '职业教育', path: '/industry' },
          { title: '产业实训基地', path: '/industry' },
          { title: '产品供应链资源服务', path: '/industry' },
          { title: '客服外包服务', path: '/industry' },
          { title: '产业金融服务', path: '/industry' },
          { title: '仓储物流服务', path: '/industry' },
          { title: '园区运营', path: '/industry' },
        ],
        
        // 电商咨询
        consulting: [
            { title: '玺承电商俱乐部', path: '/eConsultation', activeIndex: 'eConsultation', desc: '电商咨询创导者' },
            { title: '亿元俱乐部', path: '/billionClub', activeIndex: 'billionClub', desc: '结交100位亿级电商老板' },
            { title: '电商组织研学中心', path: '/researchCenter', activeIndex: 'researchCenter', desc: '组织力 | 驱动业务不断突破' },
            { title: '抖音电商俱乐部', path: '/tiktokClub', activeIndex: 'tiktokClub', desc: '抖音平台销售运营标准体系' },
            { title: '电商财税陪跑营', path: '/csClub', activeIndex: 'csClub', desc: '抖音平台销售运营标准体系' },
            { title: '京东陪跑营', path: '/jdClub', activeIndex: 'jdClub', desc: '多维度解析京东平台' },
            { title: '拼多多陪跑营', path: '/pddClub', activeIndex: 'pddClub', desc: '解决在平台的渠道布局和经营难点' },
            { title: '小红书陪跑营', path: '/redBookClub', activeIndex: 'redBookClub', desc: '内容裂变实现全平台全域内容布局' },
            { title: '亚马逊陪跑营', path: '/ymxClub', activeIndex: 'ymxClub', desc: '布局产品体系打造专属产品矩阵' },
            { title: '1688陪跑营', path: '/aliClub', activeIndex: '', desc: '抖音平台销售运营标准体系' },
            { title: '财税专项咨询', path: '/csSepcClub', activeIndex: '', desc: '重建电商管理标准，让财税成为生产力' },
            { title: '企业全岗位技能提升中心', path: '/allClub', activeIndex: '', desc: '为电商企业量身打造的岗位职能培训课程' },
        ],
        // 电商数智
        service: [
          { title: 'DGM电商数据增长模型', path: '/digitalIntel' },
          { title: 'BI数字化管理系统', path: '/digitalIntel' },
          { title: '京东大数据调研系统', path: '/digitalIntel' },
          { title: '抖音大数据调研系统', path: '/digitalIntel' },
          { title: '拼多多大数据调研系统', path: '/digitalIntel' },
          { title: '亚马逊大数据调研系统', path: '/digitalIntel' },
          { title: '数据运营工具', path: '/digitalIntel' },
          // { title: '达官电商系统', path: '/digitalIntel' },
          { title: '营销卖点分析', path: '/digitalIntel' },
          { title: '客户需求分析', path: '/digitalIntel' },
          { title: '电商辞海', path: '/digitalIntel' },
          { title: '选款分析', path: '/digitalIntel' },
          { title: '评价分析', path: '/digitalIntel' },
          { title: '价值评估', path: '/digitalIntel' },
          { title: '市场数据', path: '/digitalIntel' },
        ],
        // 关于玺承
        about: [
          { title: '玺承资本', path: '/capitalism' },
          { title: '玺承范儿', path: '/xcFan' },
          { title: '客户案例', path: '/studentColumn' },
          { title: '关于玺承', path: '/aboutUs' },
        ]
      },
      // 无需表单
      noFormList: ['/xcFan', '/posList', '/ideaList', '/industry', '/eCircle', '/studyTour', '/digitalIntel', '/capitalism', '/studentColumn', '/aboutUs'],
      menuConfig: {
        leaveMenu: true,
        leaveSubMenu: true,
        init: false,
        mainDom: null,
        doms: []
      }
    }
  },
  computed: {
    //  是否收缩
    isExpand () {
      return  this.expand  || this.isSticky 
    },
    isNoForm () {
      return !this.noFormList.includes(this.currentPath)  
    },
    isExpandSubMenu () {
      return !(this.menuConfig.leaveMenu && this.menuConfig.leaveSubMenu)
    },
    // 滚动率
    rate () {
      // 根据主菜单栏高度计算
      let rate = (this.scrollTop / this.headerHeight) / 2;
      rate = rate > 1 ? 1 : rate;
      return rate
    },
    // 主菜单栏字体颜色
    menuTextColor () {
      const rate = this.rate
      if (this.isWhiteTheme)  {
        if (process.browser) {
          document.querySelectorAll('.el-submenu__icon-arrow.el-icon-arrow-down').forEach(item => item.style.color = rate <= 0 ? '#fff' : `rgba(32, 33, 36, ${rate})`)
        }
        return rate <= 0 ? '#fff' : `rgb(32, 33, 36, ${rate})`
      } else {
        if (process.browser) {
          document.querySelectorAll('.el-submenu__icon-arrow.el-icon-arrow-down').forEach(item => item.style.color = 'rgba(32, 33, 36)')
        }
      }
      return 'rgb(32, 33, 36)'
    },
    // 菜单栏激活字体颜色
    menuTextActiveColor () {
      const rate = this.rate
      if (this.isWhiteTheme)  {
        if (process.browser) {
          document.querySelectorAll('.el-menu-item.is-active::after').forEach(item => item.style.color = rate <= 0 ? '#fff' : `rgba(32, 33, 36, ${rate})`)
        }
        return rate <= 0 ? '#fff' : `rgba(225,37,27,${rate})`
      }
      return this.isWhiteTheme ? '#fff' : `#E1251B`
    },
    menuLogoColor () {
      const rate = this.rate
      if (this.isWhiteTheme)  {
        return rate <= 0 ? '#fff' : `rgb(0,0,0, ${rate})`
      }
      return 'rgb(0,0,0)'
    },
    menuLogoRedColor () {
      const rate = this.rate
      if (this.isWhiteTheme)  {
        return rate <= 0 ? '#fff' : `rgba(229,0,0,${rate})`
      }
      return this.isWhiteTheme ? '#fff' : `rgb(229,0,0)`
    },
    // 沉浸式
    headerStyle () {
      let rate = this.rate
      // 非沉浸式
      if (!this.isImmersive) { rate = 1 }
      const backgroundColor = `rgba(255, 255, 255, ${rate})`
      return {
        backgroundColor: backgroundColor,
        boxShadow: `0 ${rate}px ${5*rate}px 0 rgba(0,0,0,.1)`
        // borderBottom: `1px solid rgba(244,244,244, ${rate})`
      }
    },
    // 监听路由计算当前页签
    activeMenu() {
      const route = this.$route
      const { meta, path, query } = route
      if (query.anchor && !['eServices'].includes(meta.activeMenu)) return query.anchor
      if (meta.activeMenu) return meta.activeMenu
      return path
    },
    // 验证码过渡动效
    codeClass () {
      return this.info.popularizePhone ? 'visible' : 'hidden'
    },
    userFormText () {
      let str = ''
      switch(this.currentPath){
        case '/home':
          str = '立即申请 店铺诊断咨询'
          break;
        case '/eServices':
          str = '立即对接 服务资源'
          break;
        default:
          str = '立即咨询 报名课程'
          break;
      }
      return str
    },
    // 是否沉浸式
    isImmersive () {
      // 非沉浸式路由名单
      return !['/searchResult', '/eServices', '/searchDetail', '/eInformation', '/eInformationDetail', '/eInformationSearch'].includes(this.currentPath) && !this.isExpandSubMenu
    },
    // 白色主题
    isWhiteTheme () {
      // 白色主题路由名单
      return ['/studyTour', '/jdCombat','/pddCombat','/redBook','/eCircle', 
              '/combatClass', '/eGlobal', '/eTaoBao', '/xcFan', '/posList', 
              '/ideaList', '/ymxCombat','/brandClass','/performanceClass', '/combat_dy',
              '/decisionClass','/productClass', '/capitalism', '/studentColumn', '/combat_3', '/privateDomainClass',
              '/bjClass', '/discClass', '/growthClass', '/jywyh', '/ali1688Class', '/kfClass', '/tiktok', '/financeClass'].includes(this.currentPath) && !this.isExpandSubMenu 
    },
    isStickyRoute () {
      return !['/aboutUs'].includes(this.currentPath)
    }
  },
  mounted () {
    this.initSubMenu ();
    this.$nextTick(() => {
      this.headerHeight = this.$refs.header.offsetHeight
      this.footerTop = this.$refs.footer.offsetTop
      
      // 监听滚动
      window.addEventListener("scroll", this.handleScroll);
      this.$on("hook:beforeDestroy", () => window.removeEventListener('scroll', this.handleScroll) )

      let MF = this.$refs.musicF
      MF.addEventListener('scroll', () => {
          let top = this.$refs.musicF.scrollTop 
          console.log(top);
          if (top <= 0) {
              this.showScroll = true;
          } else {
              this.showScroll = false
          }

      })
    })
  },
  // beforeMount () {
  //   if (this.mainDom) {
  //     this.mainDom.removeEventListener('mouseenter')
  //     this.mainDom.removeEventListener('mouseleave')
  //   }
  //   this.doms.forEach(d => {
  //     d.removeEventListener('mouseenter')
  //     d.removeEventListener('mouseleave')
  //   })
  // },
  methods: {
    bannerClick () {
      this.bannerDialog = true;
    },
    initSubMenu() {
      const eldom = document.querySelector('.el-menu-demo');
      this.mainDom = eldom
      eldom.addEventListener('mouseenter', () => {
        this.menuConfig.leaveMenu = false;
        if (!this.menuConfig.init) {
          const doms = document.querySelectorAll('.xc-submenu-popper');
          this.doms = doms
          doms.forEach(d => {
            d.addEventListener('mouseleave', () => {
              this.menuConfig.leaveSubMenu = true;
              this.menuConfig.leaveMenu = true;
            })
            d.addEventListener('mouseenter', () => {
              this.menuConfig.leaveSubMenu = false;
            })
          })
        }
      })
      eldom.addEventListener('mouseleave', () => {
        this.menuConfig.leaveMenu = true;
      })
    },
    //在线客服
    handleManual(){
      let width = 1000
      let height = 700
      const url = 'https://tb.53kf.com/code/client/f21b1f463d4e06ef4c27fe4c125eac7b4/1'
      //window.open(url,name,specs) name为空
      // const url = 'https://tb.53kf.com/code/client/f21b1f463d4e06ef4c27fe4c125eac7b4/1'
      window.open(url,'',`width=${width},height=${height}`)
    },
    openLink (n) {
      // 跳转第三方链接
      if (n.link) {
        window.open(n.url)
      } else {
        this.$router.push(n.path)
      }
    },
    // 提交表单
    submit () {
      if(!this.flag){
        return
      }
      this.flag = false

      let _this = this
      _this.info.fromPage = location.href.split('?')[0]
      _this.info.fromType = '吸底'
      _this.$api.addNewWebsiteData(_this.info).then(res=>{
        if(res?.code){
          // _this.$message.error(res.msg)
          _this.flag= true
        }else{
          _this.$message.success({
            message:'提交成功~',
            onClose:()=>{
              _this.flag= true
            }
          })
          Object.assign(_this.$data.info, _this.$options.data().info)
        }
      })
    },
    //  滚动监听（节流 16ms 是按60HZ刷新率的显示器计算一帧所用时间）
    handleScroll: _.throttle(function  () {
      if (!this.isStickyRoute) return
      this.footerTop = this.$refs.footer.offsetTop
      if (process.browser) {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        if (this.$refs.userForm)  {
          this.userFormHeight = this.$refs.userForm?.offsetHeight
          if (this.scrollTop > this.footerTop - document.body.clientHeight + this.userFormHeight) {
            this.isSticky = true
          } else {
            this.isSticky = false
          }
        }
      }
    }, 16),
  },
  watch: {
    $route: {
      handler: function () {
        this.currentPath = this.$route.path
      },
      immediate: true
    }
  }
}
